const theme = {
  config: {
    useCustomProperties: false,
  },
  disableStyledSystemCache: true,
  space: [0, 2, 4, 8, 12, 16, 24, 32, 40, 48, 64, 96, 160, 256],
  // prettier-ignore
  fontSizes: [12, 14, 16, 18, 20, 24, 28, 32, 36, 42, 48, 54, 60, 68, 76, 84, 92],
  colors: {
    white: '#ffffff',
    black: '#262626',
    text: '#262626',
    lower: '#7F7F7F',
    muted: '#fafafa',
    placeholder: '#7F7F7F',
    background: '#ffffff',
    line: '#E6E6E6',
    primary: '#0092D8',
    secondary: '#44AF69',
    attention: '#F8333C',

    enabled: '#7f7f7f', // = lower
    disabled: '#cccccc',
    highlight: 'hsla(205, 100%, 40%, 0.125)',
    flash: {
      notice: {
        border: '#007f3d',
        bg: '#e7f1eb',
      },
      warn: {
        border: '#C47C00',
        bg: '#f9f1e5',
      },
      alert: {
        border: '#f8333c',
        bg: '#fbe7e8',
      },
    },
    auctionStatus: {
      temporary_bidding: '#0092d8',
      temporary_bidding_closed: '#0065A6',
      formal_bidding: '#fcab10',
      formal_bidding_closed: '#C47C00',
      auction_closed: '#44af69',
      finished: '#007F3D',
      declined: '#7f7f7f',
    },
    richElectricBlue: [
      '#E5F4FB', // 25
      '#CCE9F7', // 50
      '#99D3EF', // 100
      '#66BEE8', // 200
      '#33A8E0', // 300
      '#0092D8', // 400
      '#0075AD', // 500
      '#005882', // 600
      '#003A56', // 700
    ],
    mediumSeaGreen: [
      '#E9F7EE', // 25
      '#D4EFDE', // 50
      '#AADFBC', // 100
      '#7FCF9B', // 200
      '#55BF79', // 300
      '#44AF69', // 400
      '#228C46', // 500
      '#196935', // 600
      '#114623', // 700
    ],
    deepCarminePink: [
      '#FEEAEB', // 25
      '#FED6D8', // 50
      '#FCADB1', // 100
      '#FB858A', // 200
      '#F95C63', // 300
      '#F8333C', // 400
      '#C62930', // 500
      '#951F24', // 600
      '#631418', // 700
    ],
    darkTangerine: [
      '#FEF6E7', // 25
      '#FEEECF', // 50
      '#FEDD9F', // 100
      '#FDCD70', // 200
      '#FDBC40', // 300
      '#FCAB10', // 400
      '#CA890D', // 500
      '#97670A', // 600
      '#654406', // 700
    ],
    timberwolf: [
      '#FBFBF7', // 25
      '#F8F7F0', // 50
      '#F1EEE1', // 100
      '#E9E6D3', // 200
      '#E2DDC4', // 300
      '#DBD5B5', // 400
      '#AFAA91', // 500
      '#83806D', // 600
      '#585548', // 700
    ],
    darkBlueGray: [
      '#F0EFF6', // 25
      '#E1DFEE', // 50
      '#C2C0DC', // 100
      '#A4A0CB', // 200
      '#8581B9', // 300
      '#6761A8', // 400
      '#4D497E', // 500
      '#343154', // 600
      '#1A182A', // 700
    ],
    deepCarrotOrange: [
      '#FDEFEA', // 25
      '#FCE0D6', // 50
      '#FAC1AC', // 100
      '#F7A283', // 200
      '#F58359', // 300
      '#F26430', // 400
      '#B54B24', // 500
      '#793218', // 600
      '#3D190C', // 700
    ],
    sapphireBlue: [
      '#E5F0F6', // 25
      '#CCE1ED', // 50
      '#99C4DB', // 100
      '#66A6CA', // 200
      '#3389B8', // 300
      '#006BA6', // 400
      '#00507D', // 500
      '#003653', // 600
      '#001B29', // 700
    ],
    shadow: [
      '#F3F1F0', // 25
      '#E8E3E1', // 50
      '#D0C6C3', // 100
      '#B9AAA6', // 200
      '#A18D88', // 300
      '#8A716A', // 400
      '#685550', // 500
      '#453935', // 600
      '#221C1B', // 700
    ],
    greenNCS: [
      '#E5F5F1', // 25
      '#CCEBE3', // 50
      '#99D7C7', // 100
      '#66C3AA', // 200
      '#33AF8E', // 300
      '#009B72', // 400
      '#007456', // 500
      '#004E39', // 600
      '#00271C', // 700
    ],
    begonia: [
      '#FEEFF1', // 25
      '#FDE0E4', // 50
      '#FBC1C8', // 100
      '#F9A2AD', // 200
      '#F78391', // 300
      '#F56476', // 400
      '#B84B59', // 500
      '#7B323B', // 600
      '#3D191E', // 700
    ],
    safetyYellow: [
      '#FDFAE5', // 25
      '#FCF6CC', // 50
      '#F9ED9A', // 100
      '#F7E567', // 200
      '#F4DC35', // 300
      '#F1D302', // 400
      '#B59E02', // 500
      '#796A01', // 600
      '#3C3501', // 700
    ],
    gray: [
      '#F2F2F2', // 95
      '#E6E6E6', // 90
      '#D9D9D9', // 85
      '#CCCCCC', // 80
      '#BFBFBF', // 75
      '#B3B3B3', // 70
      '#A6A6A6', // 65
      '#999999', // 60
      '#8C8C8C', // 55
      '#808080', // 50
      '#737373', // 45
      '#666666', // 40
      '#595959', // 35
      '#4D4D4D', // 30
      '#404040', // 25
      '#333333', // 20
      '#262626', // 15
    ],
  },
  fonts: {
    body:
      "-apple-system, BlinkMacSystemFont, 'Helvetica Neue','游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif",
    heading:
      "-apple-system, BlinkMacSystemFont, 'Helvetica Neue','游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif",
    monospace:
      "-apple-system, BlinkMacSystemFont, 'Helvetica Neue','游ゴシック体', YuGothic, '游ゴシック', 'Yu Gothic', 'メイリオ', sans-serif",
  },
  fontWeights: {
    body: 400,
    heading: 400,
    bold: 700,
  },
  lineHeights: {
    body: 1.75,
    heading: 1.5,
  },
  letterSpacings: ['0.1em', '-0.015em'],
  // sizes: [],
  // borders: [],
  borderWidths: [0, 1, 2, 4, 6, 8],
  // borderStyles: [],
  radii: [0, 2, 4, 8, 16, 9999, '100%'],
  shadows: {
    raised: '0 1px 3px rgba(0, 0, 0, 0.25)',
    // floating: "0 1px 3px rgba(0, 0, 0, 0.25)",
    overlay: '0 2px 18px rgba(0, 0, 0, 0.25)',
    // dialog: "0 2px 18px rgba(0, 0, 0, 0.25)",
  },
  zIndices: { flash: 2000 },
  // transitions: [],
  breakpoints: ['768px', '1024px', '1056px'],

  variants: {
    card: {
      boxShadow: 'raised',
      borderRadius: 2,
      borderWidth: 0,
      borderLeftWidth: 1,
      borderStyle: 'solid',
      borderColor: 'line',
    },
  },

  text: {
    label: {
      fontWeight: 'bold',
    },
    heading: {
      fontWeight: 'bold',
    },
  },

  forms: {
    label: {
      fontWeight: 'bold',
    },
    select: {
      fontSize: [2, 3],
      fontWeight: 'body',
      cursor: 'pointer',
    },
    input: {
      fontSize: [2, 3],
      fontWeight: 'body',
    },
    textarea: {
      fontSize: [2, 3],
      fontWeight: 'body',
    },
    checkboxLabel: {
      fontSize: [2, 3],
      fontWeight: 'body',
    },
    radioLabel: {
      fontSize: [2, 3],
      fontWeight: 'body',
    },
  },
};

export default theme;
